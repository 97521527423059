<template>
  <!-- Wrapper สำหรับคอลัมน์ปัจจุบัน และคอลัมน์ถัดไป (ถ้ามีการเลือก) -->
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <sidebar
        :store-module-name="storeModuleName"
        :is-sidebar-active.sync="isSidebarActive"
        :is-selected-brand="isSelectedBrand"
        :show.sync="show"
        :data="sidebarData"
      />
      <div class="d-flex explorer-column-wrapper">
        <!-- คอลัมน์ปัจจุบัน -->
        <div class="explorer-column">
          <!-- ตัวอย่างโชว์หัวข้อ ระบุระดับ (level) ไว้ได้ -->
          <!-- {{ isSelectedBrand }} -->
          <div
            class="d-flex align-items-center justify-content-between"
            style="padding-bottom: 10px;"
          >
            <h5>
              <!-- {{ level === 0 ? "Categories" : "Sub Categories" }} -->
              {{ $t("Audio") }}:
              {{
                selectedBrand !== null
                  ? `[${showFromCurrentLanguage(selectedBrand.name)}]`
                  : ""
              }}
            </h5>
            <div>
              <span>{{ `${$t("Total")} ${localItems.length} ${$t("entries")}` }}
              </span>
              <b-button
                variant="primary"
                size="sm"
                @click="sidebarEdit({})"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </div>
          </div>
          <ul class="list-group cursor-move list-group-flush">
            <li
              v-if="localItems.length === 0"
              class="list-group-item"
            >
              ({{ $t("No data") }})
            </li>
          </ul>
          <vue-perfect-scrollbar
            ref="refBrand"
            :settings="perfectScrollbarSettings"
            style="height: 60vh;"
          >
            <draggable
              v-model="localItems"
              class="list-group cursor-move list-group-flush"
              tag="ul"
              @end="sort()"
            >
              <li
                v-for="(brand, idx) in localItems"
                :key="brand._id"
                class="list-group-item"
                :class="{
                  active:
                    selectedBrand !== null &&
                    selectedBrand._id === brand._id,
                }"
                @click="selectItem(brand)"
              >
                <!-- ชื่อ (เลือก logic แสดงภาษาเอง) -->

                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center justify-content-center">
                    <template>
                      <b-avatar
                        v-if="brand.image"
                        size="25"
                        :src="brand.image"
                        class="mr-1"
                      />
                      <b-avatar
                        v-else
                        size="25"
                        :src="require('@/assets/images/blank/no_image.png')"
                        class="mr-1"
                      />
                    </template>

                    <div class="mr-1 align-items-center d-inline-flex">
                      {{ `[${idx + 1}]` }} {{ getBrandName(brand) }}
                    </div>
                  </div>

                  <div>
                    <b-button
                      variant="info"
                      size="sm"
                      class="mr-1"
                      @click.stop="sidebarEdit(brand)"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button
                      variant="danger"
                      size="sm"
                      @click.stop="deleteData(brand._id)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </div>
                </div>
              </li>
            </draggable>
          </vue-perfect-scrollbar>
        </div>

        <!-- คอลัมน์ลูก (Recursive) -->
        <!-- สร้างเสมอหากมีการ selectItem เป็นโฟลเดอร์ (selectedBrand.type === 'folder') -->
        <!-- แม้ children จะเป็นอาเรย์ว่าง ก็ยังแสดงคอลัมน์ถัดไปพร้อมข้อความ (No data) -->
        <BrandExplorer
          v-if="selectedBrand"
          :store-module-name="storeModuleName"
          :items="selectedBrand.children"
          :is-selected-brand="selectedBrand"
          :level="level + 1"
        />
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { BAvatar, BButton, BOverlay } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Sidebar from './DataSidebar.vue'

export default {
  name: 'BrandExplorer',
  components: {
    BAvatar,
    BButton,
    BOverlay,
    draggable,
    Sidebar,
    VuePerfectScrollbar,
  },
  model: {
    prop: 'items',
    event: 'change',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isSelectedBrand: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isSidebarActive: false,
      localItems: [...this.items],
      sidebarData: {},
      show: false,
      selectedBrand: null, // เก็บ brand ที่ถูกคลิกในคอลัมน์นี้
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
    }
  },
  watch: {
    items(newVal) {
      console.log(newVal)
      this.$nextTick(() => {
        this.localItems = [...newVal]
        if (this.selectedBrand !== null) {
          const index = this.localItems.findIndex(
            e => e._id.toString() === this.selectedBrand._id.toString(),
          )
          if (index > -1) {
            this.selectedBrand = this.localItems[index]
          }
        }
      })
    },
  },

  methods: {
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            store
              .dispatch(`${this.storeModuleName}/delete`, id)
              .then(result => {
                if (this.selectedBrand) {
                  if (this.selectedBrand._id.toString() === id.toString()) {
                    this.$nextTick(() => {
                      this.selectedBrand = null
                    })
                  }
                }
                store
                  .dispatch(`${this.storeModuleName}/get`, {
                    currentPage: this.currentPage,
                    pageSize: 0,
                    searchQuery: '',
                  })
                  .then(result => {
                    this.show = false
                  })
              })
              .catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    sort() {
      const obj = {
        isSelectedBrand: this.isSelectedBrand,
        dataBrand: this.localItems,
      }
      console.log(obj)
      this.show = true

      store
        .dispatch(`${this.storeModuleName}/saveSort`, obj)
        .then(result => {
          this.$emit('change', [...this.localItems])
          store
            .dispatch(`${this.storeModuleName}/get`, {
              currentPage: this.currentPage,
              pageSize: 0,
              searchQuery: '',
            })
            .then(result => {
              this.show = false
            })
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    showFromCurrentLanguage(data) {
      if (data) {
        const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
        if (indexLang > -1) {
          return data[indexLang].value
        }
        return ''
      }
      return ''
    },

    sidebarEdit(item) {
      this.sidebarData = { ...item }
      this.isSidebarActive = true
    },
    selectItem(brand) {
      this.selectedBrand = null

      if (this.selectedBrand === null) {
        this.$nextTick(() => {
          this.selectedBrand = brand
        })
      } else if (this.selectedBrand._id === brand._id) {
        this.$nextTick(() => {
          this.selectedBrand = null
        })
      } else {
        this.$nextTick(() => {
          this.selectedBrand = brand
        })
      }
    },

    // ฟังก์ชันเลือกชื่อภาษา (เช่น th ก่อน ถ้าไม่มีใช้ en)
    getBrandName(brand) {
      const thName = brand.name.find(n => n.lang === 'th')
      if (thName) return thName.value
      const enName = brand.name.find(n => n.lang === 'en')
      if (enName) return enName.value
      return '(Untitled)'
    },
  },
}
</script>

<style scoped>
/* สไตล์ตัวอย่าง ปรับตามชอบ */

/* Wrapper หลักของคอลัมน์ปัจจุบัน + คอลัมน์ลูก ซ้อนกันแนวนอน */
.explorer-column-wrapper {
  flex: 1;
  overflow-x: auto; /* เพื่อสามารถสกอลล์ในแนวนอนได้ หากคอลัมน์เยอะ */
}

/* แต่ละคอลัมน์ */
.explorer-column {
  min-width: 500px;
  max-width: 600px;
  border-left: 1px solid #ddd;
  padding: 10px;
}

/* คอลัมน์แรกไม่ต้องมีเส้นซ้าย */
.explorer-column:first-child {
  border-left: none;
}

/* list-group-item hover */
.list-group-item {
  cursor: pointer;
}
.list-group-item:hover {
  background-color: #f1f3f5;
}
</style>
